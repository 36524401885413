
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    props: {
        currentUser: {
            type: Object,
            default: {},
            required: true
        },
    },
    data() {
        return {
            showOnboarding: true,
            step1Done: false,
            step2Done: false,
            step3Done: false,
            progress: 1,
            dynamicStyle: {
                width: '25%'
            },
            showRoles: false,
            isSelectedRole: 0,
            username: this.currentUser.display_name || '',
            usernameError: null,
            roleError: null,
            formValid: false,
            isSelectedPurpose: null,
            purposeError: null,
            isSelectedReferral: null,
            referralError: null,
            buttonMeta: 'Your information stays private, always.',
            isSelectedPlan: 'free'
        }
    },
    watch: {
        progress(newProgress) {
            // Update the dynamicStyle object when the progress changes
            this.updateDynamicStyle(newProgress);
        }
    },
    mounted() {
        this.$refs.onboardingContent.focus();
    },
    methods: {
        updateProfile(displayName = null, role = null, purpose = null, referred = null) {
            const url = `${process.env.main_API_URL}/set_user_profile_survey/`;
            // Submit the form
            var profileData = new FormData();
            if (displayName !== null && displayName.length > 0)
                profileData.append('display_name', displayName)
            if (role !== null && role.length > 0)
                profileData.append('role', role)
            if (purpose !== null && purpose.length > 0)
                profileData.append('purpose', purpose)
            if (referred !== null && referred.length > 0)
                profileData.append('referred_from', referred)
            this.isFetching = true
            axios.post(url, profileData).then(response => {
                if (response.status === 200) {
                    const profileResponse = response.data.profile;
                    this.responseProfile = profileResponse;
                    this.display_name = profileResponse.display_name;
                    this.role = profileResponse.role;
                    this.referred_from = profileResponse.referred_from;
                    this.isFetching = false
                } else {
                    this.showToaster("Something bad happened.", "error");
                    this.isFetching = false
                }
            }
            ).catch(error => { this.isFetching = false })
        },
        selectPlan(e) {
            this.isSelectedPlan = e
            if (this.isSelectedPlan === 'free') {
                this.freeButtonClick()
            } else {
                this.proButtonClick()
            }
        },
        validateReferral() {
            if (this.isSelectedReferral) {
                this.referralError = null
                this.formValid = true
            } else {
                this.referralError = 'please select an option'
                this.formValid = false
            }
        },
        selectReferral(e) {
            this.isSelectedReferral = e
            this.referralError = null
        },
        validatePurpose() {
            if (this.isSelectedPurpose) {
                this.purposeError = null
                this.formValid = true
            } else {
                this.purposeError = 'please select an option'
                this.formValid = false
            }
        },
        selectPurpose(e) {
            this.isSelectedPurpose = e
            this.purposeError = null
        },
        validateUsername() {
            if (!this.username) {
                this.usernameError = 'Please enter your name';
                return false
            } else {
                this.usernameError = null;
                return true
            }
        },
        validateRole() {
            if (this.isSelectedRole === 0) {
                this.roleError = 'Please select a role';
                return false
            } else {
                this.roleError = null
                return true
            }
        },
        validateFirstStep() {
            if (this.validateUsername() && this.validateRole()) {
                this.formValid = true
            } else {
                this.formValid = false
            }
        },
        selectRole(e) {
            this.isSelectedRole = e
            this.roleError = null
            this.showRoles = false
            this.$refs.obToggler.focus();
        },
        toggleRoles() {
            this.showRoles = !this.showRoles
        },
        updateDynamicStyle(progress) {
            // Update the dynamicStyle object based on the progress value
            this.$set(this.dynamicStyle, 'width', `${25 * progress}%`);
        },
        continueButtonClick() {
            if (!this.step1Done) {
                this.validateFirstStep()
                if (this.formValid) {
                    this.step1Done = true
                    this.progress = 2
                }
                this.$refs.onboardingContent.focus();
                this.$store.commit('updateDisplayName', this.username);
                this.$store.commit('updateUserRole', this.isSelectedRole);
                this.updateProfile(this.username, this.isSelectedRole, null, null)
                return
            }
            if (this.step1Done && !this.step2Done) {
                this.validatePurpose()
                if (this.formValid) {
                    this.step2Done = true
                    this.progress = 3
                    this.buttonMeta = 'This helps us pinpoint where to engage with potential users.'
                }
                this.$refs.onboardingContent.focus();
                this.$store.commit('updatePurpose', this.isSelectedPurpose);
                this.updateProfile(null, null, this.isSelectedPurpose, null)
                return
            }
            if (this.step1Done && this.step2Done && !this.step3Done) {
                this.validateReferral()
                if (this.formValid) {
                    this.step3Done = true
                    this.progress = 4
                    this.buttonMeta = 'No strings attached - cancel anytime.'
                }
                this.$refs.onboardingContent.focus();
                this.$store.commit('updateRefferedFrom', this.isSelectedReferral);
                this.updateProfile(null, null, null, this.isSelectedReferral)
                if (this.currentUser.is_pro_user) {
                    setTimeout(() => {
                        this.showOnboarding = false
                    }, 8500);
                }
                return
            }
        },
        freeButtonClick() {
            this.showOnboarding = false
            this.$store.dispatch('enableScroll');
           // this.$router.push("/");
        },
        proButtonClick() {
            this.showOnboarding = false
            this.$store.dispatch('enableScroll');
            this.$router.push("/pricing/");
        }
    }
}
